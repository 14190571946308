<template>
  <div>
    <div v-if="orders.data && orders.data.length" class="position-table">
      <md-table
        v-model="orders.data"
        :md-sort-fn="customSort"
        :md-sort.sync="customSortBy"
        :md-sort-order.sync="customSortOrder"
      >
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          :class="['row', { viewed: item.is_viewed }]"
          @click="openModal(item.id, item.is_viewed)"
        >
          <md-table-cell
            md-label="Номер заказа"
            md-sort-by="id"
            class="custom-field"
          >
            <span>{{ item.id }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="ID пользователя"
            md-sort-by="user_id"
            class="custom-field"
          >
            <span>{{ item.user_id }}</span>
          </md-table-cell>

          <md-table-cell md-label="Кол-во товаров" class="custom-field">
            <span>{{ getQuantity(item.order_product) }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Доставка"
            md-sort-by="delivery_type"
            class="custom-field"
          >
            <span>{{ item.delivery_type }}</span>
          </md-table-cell>

          <md-table-cell md-label="Сумма" class="custom-field">
            <span>{{ getPrice(item.order_product) }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Дата заказа"
            md-sort-by="created_at"
            class="custom-field"
          >
            <span>{{ getDate(item.created_at) }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Дата оплаты"
            md-sort-by="payment_time"
            class="custom-field"
          >
            <span>{{ getDate(item.payment_time) }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Статус"
            md-sort-by="status"
            class="custom-field"
          >
            <span>{{ item.payment_status }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Статус доставки"
            md-sort-by="delivery_status"
            class="custom-field"
          >
            <span>{{ item.delivery_status }}</span>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <div v-if="orders.meta.last_page > 1" class="pagination">
        <md-button
          class="md-primary"
          :disabled="orders.meta.current_page === 1"
          @click="$emit('prev')"
        >
          <md-icon>navigate_before</md-icon>
        </md-button>
        <div class="pages">
          <span>{{ orders.meta.current_page }}</span>
          /
          <span>{{ orders.meta.last_page }}</span>
        </div>
        <md-button
          class="md-primary"
          :disabled="orders.meta.current_page === orders.meta.last_page"
          @click="$emit('next')"
        >
          <md-icon>navigate_next</md-icon>
        </md-button>
      </div>
    </div>
    <div v-else class="my-empty empty">Пусто</div>

    <ModalOrder
      v-if="showOrderModal"
      :order="order"
      @close="
        showOrderModal = false;
        $emit('search');
      "
    />
  </div>
</template>

<script>
import ModalOrder from "@/components/Modals/Order";

import { mapState, mapActions } from "vuex";

import { formatISODate } from "@/helpers/formatDate";

export default {
  components: {
    ModalOrder,
  },

  data() {
    return {
      customSortBy: "id",
      customSortOrder: "desc",
      showOrderModal: false,
    };
  },

  computed: {
    ...mapState({
      order: (state) => state.orders.order,
      orders: (state) => state.orders.orders,
    }),
  },

  methods: {
    ...mapActions(["getCounters"]),
    ...mapActions("orders", ["getOrder", "getCdekPoint"]),

    customSort(value) {
      this.$emit("sort", {
        sortBy: this.customSortBy,
        sortOrder: this.customSortOrder,
      });

      return value;
    },

    getDate(isoDate) {
      return formatISODate(isoDate);
    },

    getQuantity(products) {
      let quantity = 0;

      products?.length &&
        products.forEach((product) => {
          quantity += product.quantity;
        });

      return quantity;
    },

    getPrice(products) {
      let price = 0;

      products?.length &&
        products.forEach((product) => {
          price += +product.price;
        });

      return price;
    },

    getStatus(status) {
      switch (status) {
        case "waiting_for_payment":
          return "Ожидает оплаты";
        case "paid":
        case "payed":
          return "Оплачен";
        case "on_the_way":
          return "В пути";
        case "completed":
          return "Завершен";
        case "canceled":
        case "declined":
          return "Отменен";
        default:
          return "";
      }
    },

    async openModal(order_id, isViewed) {
      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.getOrder(order_id);
      if (success) {
        if (!isViewed) {
          this.getCounters();
        }
        if (this.order.delivery_type === "cdek") {
          await this.getCdekPoint(this.order.delivery_point_id);
        }
        this.showOrderModal = true;
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;

  .pages {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
}

::v-deep {
  .md-table-sortable-icon {
    left: unset !important;
    right: 8px !important;
  }

  .md-table-row {
    cursor: pointer;
    font-weight: 500;

    &.row.viewed {
      opacity: 0.8;
    }

    &.row:not(.viewed) {
      background-color: #3ceaff;
    }
  }
}
</style>
