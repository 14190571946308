<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">{{ pageTitle }}</h4>
            <div class="search-wrap md-layout">
              <md-field
                class="table-input md-layout-item md-large-size-10"
                :md-clearable="true"
                @md-clear="clearHandler('id')"
              >
                <label>Номер заказа</label>
                <md-input v-model="searchId" @keyup.enter="searchHandler" />
              </md-field>

              <md-field
                class="table-input md-layout-item md-large-size-20"
                :md-clearable="true"
                @md-clear="clearHandler('user_id')"
              >
                <label>ID пользователя</label>
                <md-input v-model="searchUserId" @keyup.enter="searchHandler" />
              </md-field>

              <md-datepicker
                v-model="searchDate"
                class="datepicker table-input md-layout-item md-large-size-20"
                :md-model-type="String"
              >
                <label>Дата заказа</label>
              </md-datepicker>

              <md-button
                class="md-raised md-primary md-layout-item md-large-size-10"
                @click="searchHandler"
              >
                Поиск
              </md-button>
            </div>
          </div>
        </md-card-header>
        <md-card-content>
          <OrdersTable
            @sort="sortOrders"
            @prev="goToPage('prev')"
            @next="goToPage('next')"
            @search="goToPage"
          />
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import OrdersTable from "@/components/Tables/OrdersTable";

import {mapActions, mapMutations} from "vuex";

export default {
  components: {
    OrdersTable,
  },

  data() {
    return {
      searchId: null,
      searchUserId: null,
      searchDate: null,
      sortBy: "id",
      sortOrder: "desc",
      currentPage: 1,
    };
  },

  computed: {
    pageTitle() {
      switch (this.$route.meta.status) {
        case "new":
          return "Новые заказы";
        case "on-the-way":
          return "Заказы в пути";
        case "completed":
          return "Завершенные заказы";
        default:
          return "";
      }
    },
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    await this.searchHandler();
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("orders", [
      "searchOrders",
      "getOrder",
      "getUserOrdersById",
      "getOrdersByDate"
    ]),
    ...mapMutations("orders", ["SET_SINGLE_ORDER"]),

    clearHandler(fieldName) {
      switch (fieldName) {
        case "id":
          this.searchId = null;
          break;
        case "user_id":
          this.searchUserId = null;
          break;
        case "created_at":
          this.searchDate = null;
          break;
        default:
          break;
      }

      this.searchHandler();
    },

    goToPage(direction) {
      if (direction) {
        direction == "prev" ? this.currentPage-- : this.currentPage++;
      }
      this.searchHandler(false);
    },

    async searchHandler(toFirstPage = true) {
      this.$store.commit("SET_SHOW_LOADER", true);

      if (toFirstPage) {
        this.currentPage = 1;
      }

      if (this.searchId) {
        await this.getOrder(this.searchId);
        this.SET_SINGLE_ORDER();
      } else if (this.searchUserId) {
        await this.getUserOrdersById({
          id: this.searchUserId,
          page: this.currentPage,
        });
      } else if (this.searchDate) {
        await this.getOrdersByDate(this.searchDate);
      } else {
        await this.searchOrders({
          id: this.searchId,
          user_id: this.searchUserId,
          created_at: this.searchDate,
          status: this.$route.meta.status,
          page: this.currentPage,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
        });
      }

      this.$store.commit("SET_SHOW_LOADER", false);
    },

    sortOrders(params) {
      this.sortBy = params.sortBy;
      this.sortOrder = params.sortOrder;
      this.searchHandler(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.md-layout {
  max-width: 100%;
}

.search-wrap {
  display: flex;
  justify-content: space-between;

  & > *:not(:last-child) {
    margin-right: 8px;
  }

  & > *:not(:first-child) {
    margin-left: 8px;
  }

  .md-button {
    width: 20%;
    height: max-content;
    flex-shrink: 0;
    align-self: center;
  }

  .md-field {
    .md-input,
    label {
      color: white !important;
      -webkit-text-fill-color: white !important;
    }

    &::v-deep {
      .md-input {
        color: white !important;
        -webkit-text-fill-color: white !important;
      }

      .md-clear {
        background: transparent !important;
        box-shadow: none;
        top: 18px;

        i > svg {
          fill: white !important;
        }
      }
    }
  }

  .datepicker {
    padding-left: 0;
    padding-right: 0;

    &::v-deep {
      & > .md-icon::after {
        display: none;
      }
    }
  }
}
</style>
